export abstract class CF2Component {
  id: string
  subscribers: Record<string, (() => void)[]>
  params: Record<string, any>
  mutationOberver: MutationObserver

  constructor(public element: CF2Element) {
    this.subscribers = {}
    this.id = Array.from(this.element.classList).find((c) => c.startsWith('id'))
    for (const propertyName of Object.getOwnPropertyNames(this.constructor.prototype)) {
      if (typeof this.constructor.prototype[propertyName] === 'function') {
        this.subscribers[propertyName] = []
      }
    }

    for (const dataName in this.element.dataset) {
      if (!dataName.startsWith('param')) {
        this[dataName] = this.element.dataset[dataName]
      }
    }

    const stateNodeData = CF2Component.getStateNodeData(this.element)
    if (stateNodeData) {
      Object.assign(this, stateNodeData)
    }

    if (this.afterMount) {
      document.addEventListener('CF2:HydrateTreeInitialized', () => {
        this.afterMount()
      })
    }
  }

  static getStateNodeData(element: CF2Element): Record<string, any> {
    const id = element.getAttribute('data-state-node-script-id')
    const stateNode = id && (document.getElementById(id) as HTMLElement)
    if (id && stateNode) {
      return JSON.parse(stateNode.textContent)
    }
  }

  // eslint-disable-next-line
  mount(element?: CF2Element): void {}

  // eslint-disable-next-line
  render(): void {}

  // eslint-disable-next-line
  initialize(): void {}

  // initialize and mount were not enough for cases where there are a dependency between
  // two components that are not in the same subtree.
  // afterMount fires after all components have been mounted.
  abstract afterMount(): void

  getComponent(name: string): CF2Component {
    return this.element.querySelector<CF2Element>(`[data-page-element="${name}"]`)?.cf2_instance
  }

  getComponents(name: string): CF2Component[] {
    return Array.from(this.element.querySelectorAll<CF2Element>(`[data-page-element="${name}"]`))?.map(
      (c) => c.cf2_instance
    )
  }

  getAllComponents(): Array<CF2Component> {
    const componentList: Array<CF2Component> = []
    Array.from(this.element.querySelectorAll<CF2Element>('[data-page-element]'))?.forEach((c) => {
      c.cf2_instance && componentList.push(c.cf2_instance)
    })
    return componentList
  }

  on(eventName: string, eventHandler: () => any): void {
    if (this.subscribers[eventName]) {
      this.subscribers[eventName].push(eventHandler)
    } else {
      console.warn(`Event ${eventName} not supported by ${this.constructor.name}`)
    }
  }
  // NOTE: Build components by firstly building inner elements, and then walking up tree.
  // As we need to move from the leaf nodes to parent nodes. It also accepts a list of old
  // components in which you can re-use components built from an old list.
  static hydrateTree(parentNode?: HTMLElement): void {
    const nodes = (parentNode ?? document).querySelectorAll<CF2Element>('[data-page-element]')
    nodes.forEach((node) => {
      const closestPageElement = $(node.parentNode).closest('[data-page-element]')[0]
      if (closestPageElement == parentNode || closestPageElement == null) {
        const klassName = node.getAttribute('data-page-element').replace('/', '')
        const ComponentBuilder = window[klassName]

        if (ComponentBuilder) {
          node.cf2_instance = new ComponentBuilder(node)
          node.getComponent = () => node.cf2_instance
          node.cf2_instance.initialize()
        }

        CF2Component.hydrateTree(node as CF2Element)

        if (ComponentBuilder) {
          node.cf2_instance.mount()
        }
      }
    })
  }
}

globalThis.CF2Component = CF2Component

export interface CF2Element extends HTMLElement {
  cf2_instance: CF2Component
  getComponent: () => CF2Component
}

globalThis.CF2HydrateTreeInitialized = false
window.addEventListener('DOMContentLoaded', () => {
  if (!globalThis.CF2HydrateTreeInitialized) {
    CF2Component.hydrateTree()
    queueMicrotask(() => {
      document.dispatchEvent(new CustomEvent('CF2:HydrateTreeInitialized'))
    })
  }
  globalThis.CF2HydrateTreeInitialized = true
})

export class ForloopDrop {
  protected i = 0
  public length: number
  public constructor(length: number) {
    this.length = length
  }
  public next(): void {
    this.i++
  }
  get index0(): number {
    return this.i
  }
  get index(): number {
    return this.i + 1
  }
  get first(): boolean {
    return this.i === 0
  }
  get last(): boolean {
    return this.i === this.length - 1
  }
  get rindex(): number {
    return this.length - this.i
  }
  get rindex0(): number {
    return this.length - this.i - 1
  }
}

globalThis.CF2ForloopDrop = ForloopDrop

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
globalThis.CF2Utils = globalThis.CF2Utils ?? {}
globalThis.CF2Utils.uuidv4 = uuidv4

export class CF2ComponentSingleton {
  private static _instance: CF2ComponentSingleton
  static getInstance(): CF2ComponentSingleton {
    if (this._instance) {
      return this._instance
    }
    this._instance = new this()
    return this._instance
  }
}

// globalThis.CF2ComponentSingleton = CF2ComponentSingleton
